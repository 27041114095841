<template>
  <div class="c-row">
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Alteração dos grupos do usuário
          </span>
        </div>
      </div>

      <div class="c-row ui-mb-8">
        <div class="c-col">
          <info-card
            :items="[
              { label: 'Nome', value: selectedUser.full_name },
              { label: 'Email', value: selectedUser.email }
            ]"
          />
        </div>
      </div>

      <c-table
        :columns="columns"
        :data="groups"
      >
        <template v-slot:column-name="{ item: group }">
          <div class="c-d-flex ui-items-center ui-justify-between ui-w-full">
            <span>{{ group.name }}</span>
            <c-toggle
              :value="hasGroup(group)"
              class="ui-mr-2"
              @input="status => switchGroup(group, status)"
              :data-cy="`group-toggle-${group.name}`"
            />
          </div>
        </template>
      </c-table>

      <div class="c-row c-horizontal-end ui-mt-6">
        <div class="c-col-1/4">
          <c-button
            outline
            full
            @click="backToUsersList()"
          >
            Voltar
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton, CToggle } from '@estrategiahq/coruja-web-ui';
import CTable from '@/components/c-table/CTable';
import InfoCard from '@/components/info-card';
import { historyBackTo } from '@/utils/historyBackTo';

export default {
  components: {
    InfoCard,
    CButton,
    CToggle,
    CTable,
  },
  data () {
    return {
      columns: [{
        label: 'Nome do Grupo',
        attribute: 'name',
      }],
      selectedUser: {},
      groups: [],
    };
  },
  props: {
    loggedUser: { type: Object, required: true },
  },
  async mounted () {
    const userId = this.$route.params.id;
    const [selectedUser, groups] = await Promise.all([
      this.$s.user.getById(userId),
      this.$s.group.getAll(),
    ]);

    this.selectedUser = selectedUser;
    this.groups = groups;
  },
  methods: {
    hasGroup (group) {
      return this.selectedUser.groups?.some(i => i.id === group.id);
    },
    async switchGroup (item, shouldAdd) {
      const selectedUser = this.selectedUser;

      if (this.loggedUser.id === selectedUser.id) {
        this.$toast.show({
          type: 'error',
          text: 'Você não pode alterar seus próprios grupos. Por favor, peça para outro usuário.',
          timeout: 0,
        });
        return;
      }

      if (shouldAdd) {
        await this.$s.group.addUserToGroup(selectedUser.id, item.id);
        selectedUser.groups.push(item);
      } else {
        await this.$s.group.removeUserFromGroup(selectedUser.id, item.id);
        selectedUser.groups = selectedUser.groups.filter(g => g.id !== item.id);
      }
    },
    backToUsersList() {
      historyBackTo({
        key: 'users-list-search-url',
        router: this.$router,
        fallback: { name: 'users' },
      });
    },
  },
};
</script>
